<script>
import { getFormValues } from '../../common/dataMappers'
import { getOrderTypeFromName } from '../../common/dataMappers'
import { fade } from 'svelte/transition';
import ScrollButton from '../atoms/ScrollButton.svelte'
import FormFields from '../organisms/FormFields.svelte'
import OrderOptions from '../organisms/OrderOptions.svelte'
import schema from '../../schema'
import {writable} from 'svelte/store'
// import { form } from 'svelte-forms';

export let callBack

let formWrap
const dataSchemas = ['name', 'slogan', 'domain']
let type = 'domain';
let orderType = 'domain';
let fields = schema[type].fields
let prevType = type
let history = ['domain', 'typeOfOrder', 'registrationData', 'personalData']
let stepIndex = 0
let loading = false
let orderLevel = "1"
let orderRegularPrice = 79
let orderNonRefundable = false
let error = false
const errorMessage = writable('');
let errorTitle = '';
let orderPremiumPrice = 149
let allValues = getFormValues(schema)
let values = fields.map(f => {
    let value = f.defaultValue === undefined ? '' : f.defaultValue

    if (['checkboxGroup', 'multiSelect'].includes(f.type) || f.db === 'orderExtensions'){ value = [] }
    
    return { db: f.db, value: value }
})

let min = "149"

$: type = stepIndex === 0 ? orderType : history[stepIndex]
$: fields = schema[type].fields
$: activeSchema = schema[type]
$: values = allValues[type]

function backHandle(){
    formWrap.scrollTop = 0
    if (stepIndex === 1){
        type = orderType
    }
    stepIndex--
    error = false
}

function errorCallback(errorMsg, jqXHR){
    errorTitle = `Error:`
    errorMessage.set(jqXHR.responseJSON.info)
    error = true
    loading = false
}

function handleSubmit(){

    loading = true

    let dataKeys = [orderType, 'typeOfOrder', 'registrationData', 'personalData']
    let data = {}

    dataKeys.forEach(k => { allValues[k].forEach(item => { data[item.name] = item.value }) })
    
    data.orderLevel = orderLevel
    data.orderNonRefundable = orderNonRefundable
    data.orderType = getOrderTypeFromName(orderType)
    data.orderPremiumPrice = orderPremiumPrice

    if (data.orderType === 'order_dn'){
        data.orderExtensions = data.orderExtensions.join(',')
        data.orderAdditionalExtensions = data.orderAdditionalExtensions .join(',')
    }
    console.log('data', data)
    console.log('callBack', callBack)
    callBack(data, () => {}, errorCallback)

}

function checkUserExist(success, failure, data, clearField){

    let xhr = new XMLHttpRequest();

    clearField('userMail')
    clearField('userName')
    loading = true
    xhr.open('POST', 'api/user/check')
    xhr.responseType = 'json'
    xhr.onload = () => {
        
        if (xhr.status >= 200 && xhr.status < 300) {
            console.log('xhr.response.userExists', xhr.response.userExists)
            if (xhr.response.userMailExists === 1 || xhr.response.userNameExists === 1){
                if (xhr.response.userMailExists === 1) failure('userMail', 'This e-mail already exists')
                if (xhr.response.userNameExists === 1) failure('userName', 'This name already exists')
            } else{
                success()
            }
        } else {
            failure()
        }
        
    }
    xhr.onerror = failure
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.send(JSON.stringify(data))

}

function nextHandle(e) {
    console.log('values', values)
    console.log('hererer')
    if (loading !== true){
        
    }
    let form = document.getElementById('order-form')
    let isValid = form.checkValidity()
    const stepForward = () => {

        function getValue(key){ 
            let val = (values.find(v => v.name === key) || {value: null}).value
            return Array.isArray(val) ? val.join(',') : val 
        }

        window.dataLayer = window.dataLayer || [];

        // Google analytics
        let step1Data  = {}, step2Data, step3Data = {}
        if (stepIndex === 0){
            step1Data = {
                'event': 'newOrderClickedStep1',
                'orderType': orderType,
                'title': getValue('orderTitle'),
                'detailedBrief': getValue('orderBody'),
                'extensions': getValue('orderExtensions'),
                'additonalExtensions': getValue('orderAdditionalExtensions'),
                'keywords': getValue('orderKeywords'),
                'forbiddenWords': getValue('orderForbiddenWords'),
                'maxLetters': getValue('orderMaxLetters'),
                'maxWords': getValue('orderMaxWords'),
                'useNumbers': getValue('orderUseNumbers'),
                'useDashes': getValue('orderUseDashes'),
                'useSlang': getValue('orderUseSlang')
            }
            window.dataLayer.push({...step1Data,
                'event': 'newOrderClickedStep1',
                'IP': window.ip,
                'locale': window.locale,
                'OS': window.OSName,
            })
        } else if ((stepIndex === 1)){
            step2Data = {'orderLevel': orderLevel, 'nonRefundable': orderNonRefundable, orderPrice: orderLevel == 0 ? orderRegularPrice : orderPremiumPrice}
            window.dataLayer.push({...step1Data, ...step2Data, 'event': 'newOrderClickedStep2'})
        } else if ((stepIndex === 2)){
            step3Data = { 'name': getValue('userName'), 'email': getValue('userMail')}
            window.dataLayer.push({...step1Data, ...step2Data, ...step3Data, 'event': 'newOrderClickedStep3'})
        
        } else if ((stepIndex === 3)){
            window.dataLayer.push({...step1Data, ...step2Data, ...step3Data,
                'event': 'newOrderClickedStep4',
                'firstName': getValue('userFirstName'),
                'secondName': getValue('userLastName'),
                'country': getValue('userCountry'),
                'city': getValue('userCity'),
                'address': getValue('userAddress'),
                'event': 'newOrderClickedStep4'
            })
        }
        
        e.preventDefault()

        if (dataSchemas.includes(type)){
            orderType = type
        }

        if (stepIndex !== history.length - 1){
            stepIndex++
            type = history[stepIndex]
            loading = false
        } else{
            loading = true
            handleSubmit() 
        }


    }
    const failure = (field, description) => {

        let fieldIdx = fields.findIndex(f => f.db === field)
        
        if (fieldIdx > -1){
            fields[fieldIdx] = {...fields[fieldIdx], description, invalid: true}
        }

        document.querySelector("input[name=userMail]").focus()
        loading = false

    }
    const clearField = (field) => {

        let fieldIdx = fields.findIndex(f => f.db === field)
        
        if (fieldIdx > -1){
            fields[fieldIdx] = {...fields[fieldIdx], description: '', invalid: false}
        }

    }

    formWrap.scrollTop = 0

    if (isValid){

        if (history[stepIndex] === 'registrationData'){

            let userMail = values.find(v => v.name === 'userMail')
            let userName = values.find(v => v.name === 'userName')

            checkUserExist(stepForward, failure, {userMail: userMail.value, userName: userName.value}, clearField)

        } else if (history[stepIndex] === 'domain' && type === 'domain'){ // Custom checkboxGroup validation

            let orderExtensions = values.find(v => v.name === 'orderExtensions')
            let orderAdditionalExtensions = values.find(v => v.name === 'orderAdditionalExtensions')
            let orderExtensionsIdx = values.findIndex(v => v.name === 'orderExtensions')
            let isString = typeof orderExtensions.value === "string"
            let isArray = Array.isArray(orderExtensions.value)
            let check = document.getElementById('orderExtensions-0')

            if ((isString || isArray && orderExtensions.value.length === 0) && orderAdditionalExtensions.value.length === 0){
                check.setCustomValidity('Choose at least one value.');
                check.reportValidity();
            } else{
                if (isString) values[orderExtensionsIdx].value = []
                check.setCustomValidity('');
                stepForward()
            }

        } else if (type === 'typeOfOrder'){
            let p = document.getElementById('priceInput')
            if (orderLevel == 1){
                min = "149"
                if (p.value < 149){
                    // p.setCustomValidity('Value must be greater than or equal to 149')
                    p.reportValidity()
                } else{
                    stepForward()
                }
            } else{
                min = ""
                // p.setCustomValidity('')
                stepForward()
            }
        } else{
            stepForward()
        }
        
    } else{
        form.reportValidity()
    }

    return false
}

</script>

<div class="modal order-modal">
    <div class="modal-background"></div>
    <div class="modal-content order-form">
        <header class="modal-card-head">
            <p class="modal-card-title">{activeSchema.header}</p>
            <button id="close-order-button" class="delete" aria-label="close"></button>
        </header>
        <section bind:this={formWrap} class="modal-card-body">

            {#if error}
                <div class="error-text-wrap">
                    <h2 class="title is-danger" style="color: #f14668">Error:</h2>
                    {$errorMessage}
                </div>
            {:else}
                <ScrollButton bind:wrap={formWrap}/>
                <form id="order-form">

                    {#if dataSchemas.includes(type)}
                        <div class="order-description-wrap">
                            <div class="field">
                                <label class="label">Type of order:</label>
                                <div class="control">
                                    <div class="select">
                                        <select bind:value = {type} on:change>
                                            <option value="domain">Domain name</option>
                                            <option value="name">Name</option>
                                            <option value="slogan">Slogan</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="order-description card">{activeSchema.description}</div>
                        </div>

                    {/if}

                    {#if type === 'personalData'}
                        <p class="title personal-data-description">The content of these fields is kept private and will not be shown publicly.</p>
                    {/if}

                    {#if type !== 'typeOfOrder'}
                        <FormFields values = {values} fields = {fields} />
                    {/if}
                    
                    {#if type === 'typeOfOrder'}
                        <OrderOptions 
                            bind:orderPremiumPrice={orderPremiumPrice} 
                            bind:orderLevel={orderLevel} 
                            bind:orderNonRefundable={orderNonRefundable}
                            orderRegularPrice={orderRegularPrice}
                            min = {min}
                        /> 
                    {/if}

                    {#if type === 'registrationData'}<p>Do you have account? <a href="/user/login">Login</a></p>{/if}

                </form>
            {/if}
        </section>
        <footer class="modal-card-foot">
            {#if stepIndex > 0}
                <button class="button" disabled={loading} on:click = {backHandle}>Previous</button>
            {/if}
            <button class="button is-primary" disabled={loading || error} class:is-loading={loading || error} on:click = {nextHandle}>
                {#if stepIndex < history.length - 1}Next{:else}Submit{/if}
            </button>
        </footer>
    </div>
</div>

<style>
    .order-description-wrap{
        display: flex;
        flex-direction: row;
    }
    .order-description{
        margin-left: 10px;
        padding: 7px;
        font-size: 13px;
    }
    @media screen and (max-width: 500px){
        .order-description-wrap{
            flex-direction: column;
        }
        .order-description{
            margin: 10px 0 10px 0;
            padding: 7px;
        }
    }
</style>