<script>

//Radio value type of order 0, 1, 2
export let orderPremiumPrice;
export let orderLevel;
export let orderNonRefundable;

export let min
export let orderRegularPrice
let priceInput

</script>
<form class="box">

    <div class="field">
        <div class="control">
            <div class="level required my-option">
                <label class="radio">
                    <input bind:group={orderLevel} value="0" type="radio" name="level"/>
                    Regular order - ${orderRegularPrice}
                </label>
            </div>
            <ul class="l block my-list" type="circle">
                <li>All registered contributors permitted to submit</li>
                <li>Refundable</li>
            </ul>  
        </div>
    </div>

    <div class="field">
        <div class="control">
            <div class="level required my-option">
                <label class="radio">
                    <input 
                        bind:group={orderLevel} 
                        value="1" 
                        type="radio" 
                        name="level"
                    />
                    Premium order - $
                    <input id="priceInput" bind:this={priceInput} bind:value={orderPremiumPrice} name="orderPremiumPrice" class="input orderInput" size="4" min={min} type="number" inputmode="numeric" required title="125$ is a minimum price for an premium order." />
                    <span class="tag is-primary">Recommended</span>
                </label>
            </div>
            <ul class="l block my-list" type="circle">
                <li>Priority. Your order stays on top until completed</li>
                <li>Quality. Only high-ranking contributors permitted to submit</li>
                <li>Security. Conceal the name you’ve chosen from others</li>
                <li>Refundable</li>
            </ul>  
        </div>
    </div>
    
    <div class="field">
        <div class="control">
            <div class="level refundable-checkbox required my-option">
                <label class="radio has-text-weight-bold">
                    <input type="checkbox" bind:checked={orderNonRefundable} name="level"/>
                    Attract top authors by making your order non-refundable
                </label>
            </div>
            <p class="l ref-description">
                Check this box to get our top guns working on your order. Non-refundable orders have an increased level of trust. They stand out from other orders and receive the best suggestions from our elite authors.
            </p>
        </div>
    </div>

</form>

<style>
    .l{
        color: #898989 !important;
    }

    .my-option{
        margin: 0.5rem !important;
    }

    .my-list{
        margin-left: 25px !important;
        list-style: circle !important;
    }
    .wrapHeader{
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
        text-align: center;
    }
    .orderInput{
        width: unset;
        height: 24px;
    }
    .ref-description{
        margin-left: 8px !important;
    }
</style>