<script>
    import { onMount } from "svelte";

    export let wrap // Node to scroll positon
    let scrollTop // Node to scroll positon
    let isBottom
    let isDown = false

    function handleScroll(){ isDown = (wrap.scrollTop + wrap.offsetHeight) >= wrap.scrollHeight && wrap.scrollHeight !== 0 ? true : false }
    function handleClick(){ wrap.scrollTop = (wrap.scrollTop + wrap.offsetHeight) >= wrap.scrollHeight ? 0 : wrap.scrollHeight }

    $:{
        if (wrap !== undefined){ 
            wrap.onscroll = handleScroll
            handleScroll()
            hasNoScroll = [undefined, null].includes(wrap) ? true : wrap.scrollHeight > wrap.clientHeight
        }
    }
    $: hasNoScroll = [undefined, null].includes(wrap) ? true : wrap.scrollHeight > wrap.clientHeight

</script>

<button 
    on:click = {handleClick} 
    class:btn-down-scrolled = {isDown} 
    class="button is-primary button-scroll__down"
>&#x2193;</button>

<style>

    .button-scroll__down{
        width: 30px;
        height: 30px;
        min-height: 30px;
        min-width: 30px;
        position: absolute;
        bottom: 105px;
        right: 30px;
        align-self: center;
        color: #fff;
        z-index: 100;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        padding: 0;
        opacity: 0.9;
        transition: transform 0.3s;
    }

    .btn-down-scrolled{
        transform: rotate(180deg)
    }

</style>