<script>
export let header;
export let options;
export let description;
export let name;
export let htmlTags;
export let value;
export let db;

function checkHandle(e){
    options.forEach((o, i) => { document.getElementById(`${db}-${i}`).setCustomValidity('') })
}

</script>

<div class="field">
    <label class="label">{header}</label>
    <div class="control">
        <div class="level required checkbox-wrap">
			{#each options as option, i}
                <label class="checkbox checkbox-item">
                    <input 
                        on:click = {checkHandle}
                        class:group-required={htmlTags.required}
                        type="checkbox" 
                        bind:group={value}
                        value={option.value}
                        name={db}
                        id={`${db}-${i}`}
                    />
                    {option.name}
                </label>
			{/each}
        </div>
        <p class="help">{description}</p>
    </div>
</div>

<style>
    .checkbox-item{
        margin: 10px;
        margin: 10px;
        flex: 1 0 25%;
    }
    .checkbox-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
    }
</style>